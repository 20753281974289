import { dateStrToDate } from '@open-tender/utils'

export const getActiveElement = (elements: HTMLElement[], offset = 0) => {
  return elements
    .filter((i) => i.getBoundingClientRect().top <= offset)
    .reduce((max: HTMLElement | null, i: HTMLElement) => {
      return max &&
        max.getBoundingClientRect().top > i.getBoundingClientRect().top
        ? max
        : i
    }, null)
}

// https://stackoverflow.com/questions/51229742/javascript-window-scroll-behavior-smooth-not-working-in-safari
export const smoothHorizontalScrolling = (
  container: HTMLElement,
  time: number,
  amount: number,
  start: number
) => {
  let eAmt = amount / 100
  let curTime = 0
  let scrollCounter = 0
  while (curTime <= time) {
    window.setTimeout(shs, curTime, container, scrollCounter, eAmt, start)
    curTime += time / 100
    scrollCounter++
  }
}

const shs = (
  elem: HTMLElement,
  scrollCounter: number,
  eAmt: number,
  start: number
) => {
  elem.scrollLeft = eAmt * scrollCounter + start
}

export const isExpired = (
  expires_at: string | null,
  end_date: string | null
) => {
  const isExpired = expires_at ? dateStrToDate(expires_at) < new Date() : false
  const isEnded = end_date ? dateStrToDate(end_date) < new Date() : false
  return isExpired || isEnded
}
